import React from 'react';
import styled from 'styled-components';
import { breakpoints, spacing } from '@naf/theme';
import { CardBenefits, CardVariant, ScrollList } from '@naf/cards';
import { useHistory } from 'react-router-dom';
import { fill } from '@cloudinary/url-gen/actions/resize';
import { AdvancedImage, lazyload } from '@cloudinary/react';
import { useCloudinary } from '../../hooks/useCloudinary';
import useSelector from '../../redux/typedHooks';
import { BenefitsType } from '../../../../types/benefitsType';
import { ApplicationState } from '../../redux/modules/application';
import { MembershipBenefitReference } from '../../../../types/membershipBenefitReferenceType';
import MobileAppIllustration from '../../../assets/images/phone-image.svg?url';
import defaultBenefitImage from '../../../assets/images/defaultLocalBenefit.svg?url';

interface Props {
  benefitItems: Record<string, BenefitsType> | BenefitsType[] | MembershipBenefitReference[];
  listView?: boolean;
  showAppLink?: boolean;
  size?: 'standard' | 'small';
}

const BenefitItemCard = ({
  benefit: {
    image,
    name,
    title,
    slug,
    isNewBenefit,
    isCampaign,
    hasActiveCampaign,
    partner,
    id,
    discountBadge,
    isLocalContent,
    localBenefitImage,
  },
  listView,
  app,
  size,
}: {
  benefit: BenefitsType | MembershipBenefitReference;
  listView?: boolean;
  app?: ApplicationState;
  size: 'standard' | 'small';
}) => {
  // TODO: add favorites handling
  const handleFavorites = () => false;
  const itemSlug = slug || id;
  const history = useHistory();
  const cld = useCloudinary();

  const cldImage =
    app &&
    image &&
    image.publicId &&
    cld.image(image.publicId).resize(fill().width(768).height(432)).quality('auto:eco').format('auto');
  const localImage = localBenefitImage?.url ? localBenefitImage?.url : defaultBenefitImage;
  const handleLocalPath = () => {
    if (history.location.pathname.includes('/fordeler'))
      return history.location.pathname.replace(/\/[^/]*$/, `/${slug}`);
    return `${history.location.pathname}/fordeler/${slug}`;
  };

  return (
    <StyledCardBenefits
      listView={listView}
      key={slug}
      advancedImage={
        cldImage && (
          <AdvancedImage
            alt={image?.altText || ''}
            cldImg={cldImage}
            plugins={[lazyload({ rootMargin: '10px 20px 10px 30px', threshold: 0.25 })]}
          />
        )
      }
      img={
        !cldImage
          ? {
              src: isLocalContent ? localImage : image?.srcSet?.jpgLarge || '',
              alt: isLocalContent ? localBenefitImage?.caption || '' : image?.altText || '',
            }
          : undefined
      }
      onClick={() => {
        history.push(isLocalContent ? handleLocalPath() : `/medlemskap/medlemsfordeler/${itemSlug}`);
      }}
      onFavoriteClick={() => handleFavorites()}
      title={name || title}
      variant={size === 'small' ? CardVariant.Small : CardVariant.Standard}
      topLabel={
        isNewBenefit || isCampaign || hasActiveCampaign
          ? {
              text: `${isNewBenefit ? 'Ny fordel' : 'Kampanje'}`,
              variant: `${isNewBenefit ? 'signature' : 'moss'}`,
            }
          : undefined
      }
      bottomLabel={
        discountBadge
          ? {
              text: `${discountBadge}`,
              variant: 'white',
            }
          : undefined
      }
      logo={partner?.logo ? <PartnerLogo src={partner.logo.srcSet.jpgLarge} alt="Partnerlogo" /> : undefined}
    />
  );
};

const BenefitsList = ({ benefitItems, listView = false, showAppLink = false, size = 'small' }: Props) => {
  const application = useSelector((state) => state.application);
  const benefits = !(benefitItems instanceof Array) ? Object.values(benefitItems) : benefitItems;
  const handleFavorites = () => false;
  const history = useHistory();

  const cld = useCloudinary();

  const logo = useSelector((state) => state.globalSettings.settings?.logo);
  const logoImage =
    (logo &&
      logo.publicId &&
      cld.image(logo.publicId).resize(fill().width(56)).quality('auto:best').format('auto').toURL()) ||
    undefined;

  return (
    (benefitItems && !listView ? (
      <ScrollList>
        {benefits.map((benefitItem) => (
          <BenefitItemCard benefit={benefitItem} app={application} key={`s-${benefitItem.slug}`} size={size} />
        ))}
      </ScrollList>
    ) : (
      <BenefitListWrapper>
        {showAppLink && (
          <StyledCardBenefits
            listView={listView}
            key="benefitApp"
            img={{
              src: MobileAppIllustration,
              alt: 'Mobiltelefon',
            }}
            onClick={() => {
              history.push(`/naf-app`);
            }}
            onFavoriteClick={() => handleFavorites()}
            title="Få fordelene i lomma med NAF appen"
            variant={CardVariant.Small}
            logo={<PartnerLogo src={logoImage} alt="Partnerlogo" />}
          />
        )}
        {benefits.map((benefitItem) => (
          <BenefitItemCard benefit={benefitItem} app={application} key={benefitItem.slug} listView size={size} />
        ))}
      </BenefitListWrapper>
    )) || null
  );
};

const PartnerLogo = styled.img`
  height: 24px;
`;

const BenefitListWrapper = styled.div`
  div:nth-of-type(4n) {
    margin-right: 0;
  }
`;

const StyledCardBenefits = styled(CardBenefits)<{ listView?: boolean }>`
  margin-bottom: ${(props) => (props.listView ? '32px' : '0px')};

  div:first-child {
    width: 100%;
  }

  &:hover {
    ${PartnerLogo} {
      transform: none;
    }
  }

  figure ~ span {
    height: unset;
    min-height: ${spacing.space24};
  }

  @media (max-width: ${breakpoints.m}) {
    margin-bottom: ${spacing.space32};
    width: 100%;
    min-width: ${(props) => (props.listView ? '100%' : '75%')};
  }
`;

export default BenefitsList;
